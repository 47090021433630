const MONTHS = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"];

function ReformatSocialMediasToList(data){
	let t = document.createElement("ul");
	for(let i in data){
		let d = data[i];
		let li = document.createElement("li");
		let link = document.createElement("a");
		link.href = d["link"];
		link.setAttribute("target","_blank");
		let icon = document.createElement("i");
		icon.setAttribute('aria-hidden','true');
		icon.className = d["icon"];

		link.appendChild(icon);
		li.appendChild(link);
		t.appendChild(li);
	}

	return t.outerHTML;
}

function ReformatSocialMediasForCV(data){
	let t = document.createElement("table");
	let tbody = document.createElement("tbody");

	for(let i in data){
		let tr = document.createElement("tr");
		let td1 = document.createElement("td");
		let icon = document.createElement("i");
		icon.setAttribute("aria-hidden","true");
		icon.className = data[i]["icon"];

		let td2 = document.createElement("td");
		let link = document.createElement("a");
		link.setAttribute("href",data[i]["link"]);
		link.setAttribute("target","_blank");

		let link_text = data[i]["link"];
		if(link_text.substr(0,7) === "http://"){
			link_text = link_text.substr(7);
		}else{
			link_text = link_text.substr(8);
		}

		if(link_text.substr(0,4) === "www."){
			link_text = link_text.substr(4);
		}

		if(link_text[link_text.length-1] === '/'){
			link_text = link_text.substr(0,link_text.length-1)
		}

		link.innerHTML = link_text;

		td1.appendChild(icon);
		tr.appendChild(td1);
		td2.appendChild(link);
		tr.appendChild(td2);
		tbody.appendChild(tr);
	}
	t.appendChild(tbody);
	return t.outerHTML;
}

function ReformatExperiences(data){
	let s = "";
	for (let i in data){
		s += ReformatExperience(data[i]).outerHTML;
	}
	return s;
}

function ReformatExperience(data){
	let t = document.createElement("div");
	t.className="experience";
	let title_experience = document.createElement("h3");
	let span_date = document.createElement("span");

	if(data["date"]["end"] == null){

		span_date.innerHTML = "Depuis "+DateObjToStr(data["date"]["start"]);
	}else{
		span_date.innerHTML = DateObjToStr(data["date"]["start"])+" — "+DateObjToStr(data["date"]["end"]);
	}

	title_experience.innerHTML = span_date.outerHTML + data["title_exp"];

	let title_post = document.createElement("h5");
	title_post.innerHTML = data["title_post"];

	let em = null;

	if(data["additionnal_data"] != null){
		em = document.createElement("em");
		let icon = document.createElement("i");
		icon.setAttribute("aria-hidden","true");
		icon.className = "fas fa-award";

		em.innerHTML = icon.outerHTML + data["additionnal_data"];
	}

	let p = document.createElement("p");
	p.innerHTML = data["data"];

	t.appendChild(title_experience);
	t.appendChild(title_post);
	if(em != null){
		t.appendChild(em);
	}
	t.appendChild(p);
	return t;
}
function ReformatSkills(data){
	let s = "";
	for(let i in data){

		let p = document.createElement("p");
		p.innerHTML = data[i]["name"];
		if(data[i]["details"] != null){
			let span = document.createElement("span");
			span.innerHTML = data[i]["details"];
			p.innerHTML += span.outerHTML;
		}
		s += p.outerHTML;
	}
	return s;
}

function getJournals(){
	return getPublications()["journals"];
}
function getProceedings(){
	return getPublications()["proceedings"];
}
function getThesis(){
	return getPublications()["thesis"];
}



function DateObjToStr(d){
	let s = "";

	if(d["month"] !== undefined){
		s += MONTHS[d["month"]-1]+" ";
	}
	s += d["year"];

	return s;
}
function ToList(data,start_number){
	if(start_number === undefined){	start_number =1;}

	let s = document.createElement("ol");
	s.setAttribute("start",start_number);
	for(let i in data) {
		let li = document.createElement("li");
		li.innerHTML = data[i];
		s.appendChild(li);
	}
	return s.outerHTML;
}

function ContactForm(){
	// TODO: Finir le formulaire de contact - L'envoi du courriel
	let values = $("#contact-form").serializeArray();

	alert("Not yet implemented.");
}
function DownloadPDF(){
	// TODO: Curriculum to PDF
	alert("Not yet implemented.");
}